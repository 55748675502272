import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import { breakpoints, colors } from '../../styles/utilities/settings';

const Board = () => {
  const {
    sanitySiteSettings: { members, affiliations },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        members: _rawBoardMembers(resolveReferences: { maxDepth: 10 })
        affiliations: _rawAffiliations(resolveReferences: { maxDepth: 10 })
      }
    }
  `);
  return (
    <SBoard>
      <Wrapper>
        <div>
          <h3>Board of Directors</h3>
          {members.map(({ _key, name, title }) => (
            <div className="member" key={_key}>
              <strong>{title}</strong>
              {name}
            </div>
          ))}
        </div>
        <div>
          <h3>Organization Affiliations</h3>
          {affiliations.map(({ _key, name, link }) => (
            <div key={_key}>
              <a href={link} target="_blank" rel="noreferrer">
                {name}
              </a>
            </div>
          ))}
        </div>
      </Wrapper>
    </SBoard>
  );
};

export default Board;

const SBoard = styled.div`
  padding: 120px 0 100px;
  color: ${colors.white};

  ${Wrapper} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
    }

    > div {
      width: 100%;

      + div {
        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          margin-top: 50px;
        }
      }
    }
  }

  .member {
    + .member {
      margin-top: 20px;
    }
  }

  a {
    text-decoration: none;
    color: ${colors.white};

    &:hover {
      color: ${colors.gold};
    }
  }

  h3 {
    line-height: 1.1;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 100%;
      max-width: 250px;
    }
  }

  h3,
  strong {
    color: ${colors.gold};
  }

  strong {
    display: block;
    font-weight: 400;
    text-transform: uppercase;
  }
`;
