import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { colors, font } from '../../styles/utilities/settings';
import SvgLoader from '../helpers/SvgLoader';

const Event = ({
  content: {
    title,
    cityStateZip,
    locationName,
    street,
    description,
    time,
    date,
    link,
  },
}) => (
  <SEvent>
    <div>
      <h4>
        <SvgLoader
          size={20}
          name="tireIcon"
          color={colors.gold}
          secondaryColor={colors.darkerGray}
        />
        <span>{title}</span>
        {description && <p>{description}</p>}
      </h4>
      <p>
        <SvgLoader size={24} name="pin" color={colors.gold} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/dir//${street} ${cityStateZip}`}
        >
          {locationName && (
            <>
              <strong>{locationName}</strong>
              <br />
            </>
          )}
          {`${street} ${cityStateZip}`}
        </a>
      </p>
      <div className="info">
        <SvgLoader size={20} name="calendar" color={colors.gold} />
        <div className="when">
          <div className="date">{moment(date).format('LL')}</div>
          <span>{time}</span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {`Learn More about ${title}`}
          </a>
        </div>
      </div>
    </div>
  </SEvent>
);

export default Event;

export const SEvent = styled.div`
  color: ${colors.white};

  h4 {
    color: ${colors.gold};
    text-transform: uppercase;
    font-family: ${font.primary};
    line-height: 1.2;

    span {
      font-weight: 600;
    }

    p {
      color: ${colors.white};
      font-size: 16px;
      text-transform: none;
      width: 100%;
      line-height: 1.5;
      padding-left: 40px;
    }
  }

  h4,
  p {
    margin: 10px 0;
  }

  h4,
  p,
  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > svg {
      width: 20px;
      padding-right: 20px;
      position: relative;
      top: 5px;
      box-sizing: content-box;
    }

    > span,
    > a,
    > div {
      display: block;
      width: calc(100% - 40px);
    }
  }

  .info {
    a {
      color: ${colors.gold};
      text-transform: uppercase;
      margin-top: 10px;

      &:hover {
        color: ${colors.white};
      }
    }
  }

  a {
    color: ${colors.white};
    display: block;
    text-decoration: none;

    &:hover {
      color: ${colors.gold};
    }
  }
`;
