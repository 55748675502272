import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SvgLoader from '../helpers/SvgLoader';
import { colors, breakpoints, font } from '../../styles/utilities/settings';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import { ctaButton } from '../../styles/utilities/elements';

const evenNum = (num) => num % 2 === 0;

const TileContent = ({ tile, index }) => (
  <>
    {tile.icon && (
      <SvgLoader
        name={tile.icon.icon}
        size={40}
        color={evenNum(index) ? colors.orange : colors.green}
      />
    )}
    {tile.heading && <h5>{tile.heading}</h5>}
    <p>{tile.link.copy}</p>
  </>
);

const TiledLinks = ({ content }) => (
  <STiledLinks>
    {content.tiles.map((tile, index) => (
      <Tile even={evenNum(index)} key={tile.heading + index}>
        <div className="images">
          {tile.backgroundImages.map((image) => (
            <BackgroundImage width={400} src={image} />
          ))}
        </div>
        <div>
          {tile.link.newTab ? (
            <a href={tile.link.url} target="_blank" rel="noopener noreferrer">
              <TileContent tile={tile} index={index} />
            </a>
          ) : (
            <Link to={tile.link.url}>
              <TileContent tile={tile} index={index} />
            </Link>
          )}
        </div>
      </Tile>
    ))}
  </STiledLinks>
);

export default TiledLinks;

const STiledLinks = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 320px;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    text-align: center;
  }

  .images {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    height: 320px;
    width: 100%;

    @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
      flex-wrap: wrap;
    }

    ${SBackgroundImage} {
      height: 320px;
      width: 100%;

      @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
        width: 50%;
        height: 160px;
      }
    }
  }
`;

const Tile = styled.div`
  text-transform: uppercase;
  font-family: ${font.secondary};

  a {
    position: relative;
    z-index: 3;
    ${ctaButton};
  }

  h5,
  p {
    margin: 0;
  }

  h5 {
    font-size: 25px;
    margin-top: 10px;
  }
`;
