import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import { secondaryButton } from '../../styles/utilities/elements';
import HeadingContent, { HeadingContainer } from '../modules/HeadingContent';
import BackgroundImage from '../helpers/BackgroundImage';
import { SLinksContainer } from '../modules/LinksContainer';
import Tread, { STread } from '../modules/Tread';

const CtaContent = ({
  content,
  content: { backgroundColor, ctaBackgroundImage },
}) => (
  <SCallToActionBanner
    color={backgroundColor && backgroundColor.colors.hex}
    style={{
      backgroundColor:
        !ctaBackgroundImage &&
        (backgroundColor ? backgroundColor.colors.hex : colors.blue),
      color: colors.white,
      textAlign: 'center',
    }}
  >
    <Wrapper narrow>
      <HeadingContent content={content}>
        <Tread color={colors.orange} />
      </HeadingContent>
    </Wrapper>
  </SCallToActionBanner>
);

const CallToActionBanner = ({
  content,
  content: { backgroundColor, ctaBackgroundImage },
}) => (
  <>
    {ctaBackgroundImage ? (
      <BackgroundImage
        src={ctaBackgroundImage}
        style={{
          backgroundColor: backgroundColor
            ? backgroundColor.colors.hex
            : colors.blue,
        }}
      >
        <CtaContent content={{ ...content }} />
      </BackgroundImage>
    ) : (
      <CtaContent content={{ ...content }} />
    )}
  </>
);

export default CallToActionBanner;

const SCallToActionBanner = styled.div`
  padding: 100px 0;
  position: relative;
  overflow: hidden;

  ${HeadingContainer} {
    h1,
    h2 {
      position: relative;
      display: inline-block;
      padding: 5px 20px;
      background-color: ${({ color }) => color || 'transparent'};
    }

    ${SLinksContainer} {
      > a {
        margin-top: 40px;
        ${secondaryButton};
      }
    }
  }

  ${STread} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;
