import React from 'react';
import refID from '../../js/refID';

const Video = ({ src }) => (
  <video playsInline autoPlay muted loop>
    <source
      src={`https://cdn.sanity.io/files/${process.env.GATSBY_SANITY_ID}/${
        process.env.GATSBY_SANITY_DATASET
      }/${refID(src.asset._id || src.asset._ref)}.mp4`}
      type="video/mp4"
    />
  </video>
);

export default Video;
