import React, { useState } from 'react';
import styled from 'styled-components';
import PageLink from '../helpers/PageLink';
import { breakpoints, colors } from '../../styles/utilities/settings';
import { button, absoluteCenter } from '../../styles/utilities/elements';
import Wrapper from '../../styles/utilities/Wrapper';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import SvgLoader from '../helpers/SvgLoader';
import RichText from './RichText';
import LargeFeature from '../modules/LargeFeature';
import YouTubeVideo from '../helpers/YouTubeVideo';

// Single Feature Component
const SingleFeature = ({
  flip,
  contained,
  content: { video, image, parallax, heading, copy, link },
}) => {
  const [toggleVideo, setToggleVideo] = useState(false);
  return (
    <SFeature
      flip={flip}
      className={
        video && !toggleVideo ? 'video' : `${video && toggleVideo && 'active'}`
      }
    >
      <div className="image">
        {video ? (
          <>
            {toggleVideo ? (
              <YouTubeVideo title={heading} videoUrl={video} />
            ) : (
              <>
                <a
                  href={null}
                  onClick={() => {
                    setToggleVideo(true);
                  }}
                >
                  <SvgLoader name="play" color={colors.white} />
                </a>
                <BackgroundImage
                  parallaxBg={parallax}
                  maxMin={contained ? ['50px', '-50px'] : ['100px', '-100px']}
                  src={image}
                  maxWidth={breakpoints.pageWidth / 2}
                  alt={heading}
                />
              </>
            )}
          </>
        ) : (
          <BackgroundImage
            parallaxBg={parallax}
            maxMin={contained ? ['50px', '-50px'] : ['100px', '-100px']}
            src={image}
            maxWidth={breakpoints.pageWidth / 2}
            alt={heading}
          />
        )}
      </div>

      <div className="content">
        <h3>{heading}</h3>
        {copy && <RichText content={{ copy }} noWrapper />}
        {link && link.copy && (
          <PageLink
            style={{
              marginTop: '20px',
            }}
            content={link}
          />
        )}
      </div>
      <SvgLoader name="tireIcon" color={colors.darkerGray} size={553} />
    </SFeature>
  );
};

// Single Feature Styles
export const SFeature = styled.div`
  background-color: ${colors.black};
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    display: flex;
    align-items: stretch;
    flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
  }

  &:nth-child(2n) {
    flex-direction: ${({ flip }) => (flip ? 'row' : 'row-reverse')};

    .content {
      svg {
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          left: 30px;
        }
      }
    }
  }

  &:nth-child(2n - 1) {
    .content {
      svg {
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          right: 30px;
        }
      }
    }
  }

  svg {
    position: absolute;
    right: -420px;
    z-index: 0;
    bottom: 0;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .parallax-inner {
    height: 100%;

    .image {
      height: 100%;
    }
  }

  &.video {
    .image {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${colors.blackOverlay};
        z-index: 2;
        display: block;
      }

      a {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.active {
    align-items: center;
    .image {
      overflow: hidden;
    }
  }

  > div {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 50%;
    }
  }

  .image {
    position: relative;
    z-index: 1;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding-bottom: 60%;
    }

    ${SBackgroundImage} {
      height: 100%;
      width: 100%;

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        position: absolute;
        top: 0;
        left: 0;
      }

      picture {
        top: 0;
        transform: translate(-50%, 0);
      }
    }

    svg {
      ${absoluteCenter};
      z-index: 3;
      opacity: 0.39;
    }
  }

  h3 {
    border-bottom: 4px solid ${colors.gold};
    display: inline-block;
    padding-bottom: 8px;
    line-height: 1.1;
  }

  .content {
    color: ${colors.white};
    padding: 120px 50px 120px 80px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      max-width: ${breakpoints.pageWidth / 2}px;
      width: 40%;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 50px 15px;
    }

    > a {
      ${button};
    }
  }
`;

//* **************** *///
//* **************** *///

// Feature List Component
const ImageFeatures = ({
  content: { componentLayout, features, flipImageSide },
}) => (
  <div>
    <Wrapper full={componentLayout !== 'contained'}>
      {features.map((feature) =>
        componentLayout === 'large' ? (
          <LargeFeature
            key={feature._key}
            content={{ ...feature, flipImageSide }}
          />
        ) : (
          <SingleFeature
            contained={componentLayout === 'contained'}
            key={feature._key}
            content={feature}
            flip={flipImageSide}
          />
        )
      )}
    </Wrapper>
  </div>
);

export default ImageFeatures;
