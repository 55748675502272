import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import RichText from './RichText';
import { Box } from './CampaignCallout';
import BookSession from '../forms/BookSession';

import { colors, font, breakpoints } from '../../styles/utilities/settings';
import { button } from '../../styles/utilities/elements';
import { SMessage } from '../forms/ContactForm';

const Sessions = ({
  content: { cost, destinations, formCopy, formTitle, heading },
}) => (
  <SSessions>
    <div className="button">
      <span>{`Class Cost: $${cost}`}</span>
    </div>
    <Wrapper>
      <DestinationCopy>
        <h3>{heading}</h3>
        <ul>
          {destinations.map(({ _key, distance, name }) => (
            <li key={_key}>
              <strong>{name}</strong>
              <RichText noWrapper content={{ copy: distance }} />
            </li>
          ))}
        </ul>
      </DestinationCopy>
      <Box>
        <BookSession heading={formTitle} copy={formCopy} />
      </Box>
    </Wrapper>
  </SSessions>
);

export default Sessions;

const DestinationCopy = styled.div`
  color: ${colors.white};

  @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
    margin-bottom: 50px;
  }

  h3 {
    color: ${colors.gold};
    font-size: 30px;
    border-bottom: 4px solid ${colors.gold};
    padding-bottom: 8px;
    line-height: 1.1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    + li {
      margin-top: 10px;
    }

    p {
      margin: 10px 0 0;
    }
  }

  strong {
    color: ${colors.gold};
    display: block;

    + a {
      margin-top: 20px;
      ${button};
    }
  }
`;

const SSessions = styled.div`
  padding: 100px 0;

  .button {
    text-align: center;
    margin-bottom: 100px;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      margin-bottom: 50px;
    }

    span {
      display: inline-block;
      background-color: ${colors.darkerGray};
      color: ${colors.white};
      margin: 0 auto;
      text-align: center;
      font-family: ${font.secondary};
      font-size: 30px;
      padding: 10px 20px;
    }
  }

  ${Wrapper} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      justify-content: space-between;
    }
  }

  ${Box},
  ${DestinationCopy} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: calc(50% - 50px);
    }
  }

  ${SMessage} {
    line-height: 1.2;
    color: ${colors.black};
  }
`;
