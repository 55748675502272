import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { useMediaQuery } from 'react-responsive';
import { imageBG } from '../../styles/utilities/elements';
import ImageLoader from './ImageLoader';
import ImageSizer from './ImageSizer';
import { breakpoints } from '../../styles/utilities/settings';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.ipadPort });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: breakpoints.ipadPort - 1 });
  return isDesktop ? children : null;
};

const BackgroundImage = ({
  alt,
  mobile,
  style,
  children,
  className,
  width,
  src,
}) => (
  <VisibilitySensor partialVisibility>
    {({ isVisible }) => (
      <SBackgroundImage
        preview={src.asset._ref}
        style={{ ...style }}
        className={className}
      >
        <Desktop>
          <ImageLoader src={src} width={width} alt={alt} visible={isVisible} />
        </Desktop>

        <Mobile>
          {mobile ? (
            <ImageSizer src={src} alt={alt} height={700} width={700} />
          ) : (
            <ImageLoader
              src={src}
              alt={alt}
              width={width / 2}
              visible={isVisible}
            />
          )}
        </Mobile>

        {children}
      </SBackgroundImage>
    )}
  </VisibilitySensor>
);

export default BackgroundImage;

export const SBackgroundImage = styled.div`
  position: relative;
  overflow: hidden;
  ${imageBG};
`;
