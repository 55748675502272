import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import dateFormatter from '../../js/dateFormatter';
import Wrapper from '../../styles/utilities/Wrapper';
import Event from '../modules/Event';
import { breakpoints, colors } from '../../styles/utilities/settings';

const NewEventsArray = (events) => {
  const updatedEvents = [];

  events.forEach(({ event }) => {
    if (new Date(event.date) >= new Date()) {
      updatedEvents.push({
        event: {
          _id: event._id,
          title: event.title,
          cityStateZip: event.cityStateZip,
          street: event.street,
          description: event.description,
          locationName: event.locationName,
          time: event.time,
          date: event.date,
          dateTime: new Date(event.date),
          link: event.link,
        },
      });
    }

    if (event.reoccuringDates) {
      event.reoccuringDates.forEach((reDate, index) => {
        if (new Date(dateFormatter(reDate, 1)) >= new Date()) {
          updatedEvents.push({
            event: {
              _id: event._id + index,
              title: event.title,
              cityStateZip: event.cityStateZip,
              street: event.street,
              description: event.description,
              locationName: event.locationName,
              time: event.time,
              date: dateFormatter(reDate, 1),
              dateTime: new Date(dateFormatter(reDate, 1)),
              link: event.link,
            },
          });
        }
      });
    }
  });

  const sortedEvents = updatedEvents.sort(
    (a, b) => a.event.dateTime - b.event.dateTime
  );

  return updatedEvents.length !== 0 ? sortedEvents : events;
};

const EventListing = () => (
  <StaticQuery
    query={EVENTLISTING_QUERY}
    render={({ allEvents: { events } }) => (
      <SEventListing noEvents={!NewEventsArray(events).length}>
        <Wrapper>
          <div className="listing">
            {NewEventsArray(events).length ? (
              <>
                <h2>Where You&apos;ll Find Us Next</h2>
                {NewEventsArray(events).map(({ event }, index) => (
                  <Event key={event._id + index} content={event} />
                ))}
              </>
            ) : (
              <h3
                style={{
                  color: colors.white,
                  border: 'none',
                }}
              >
                There are no events at this time.
              </h3>
            )}
          </div>
        </Wrapper>
      </SEventListing>
    )}
  />
);

export default EventListing;

const EVENTLISTING_QUERY = graphql`
  {
    allEvents: allSanityEvent(sort: { fields: date, order: ASC }) {
      events: edges {
        event: node {
          _id
          title
          cityStateZip
          time
          street
          description
          locationName
          link
          date(formatString: "ll")
          reoccuringDates
        }
      }
    }
  }
`;

const SEventListing = styled.div`
  background-color: ${colors.darkerGray};
  padding: 100px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    ${({ noEvents }) => (noEvents ? 'min-height: calc(100vh - 365px);' : '')}
  }

  h2 {
    display: inline-block;
    color: ${colors.gold};
    border-bottom: 4px solid;
    padding-bottom: 8px;
    line-height: 1.1;
  }

  .listing {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -30px;
      justify-content: space-between;
    }

    > div {
      padding: 40px 0;

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: calc(50% - 40px);
      }
    }
  }
`;
