import React from 'react';
import styled from 'styled-components';
import RichText from './contentBlocks/RichText';
import CallToActionBanner from './contentBlocks/CallToActionBanner';
import ImageFeatures from './contentBlocks/ImageFeatures';
import ImageGallery from './contentBlocks/ImageGallery';
import EventListing from './contentBlocks/EventListing';
import HeroBanner from './contentBlocks/HeroBanner';
import HeadingContent from './modules/HeadingContent';
import TiledLinks from './contentBlocks/TiledLinks';
import CampaignCallout from './contentBlocks/CampaignCallout';
import Board from './contentBlocks/Board';
import Sessions from './contentBlocks/Sessions';

const Content = ({ contentArray, mainImage }) =>
  contentArray &&
  contentArray.map((content, index) => (
    <React.Fragment key={content._key}>
      <Section id={`${content._type}_${index}`} noMargin>
        {content._type === 'heroBanner' && (
          <HeroBanner mainImage={mainImage} content={content} />
        )}
        {content._type === 'videoBanner' && (
          <HeroBanner mainImage={mainImage} content={content} />
        )}
        {content._type === 'richText' && <RichText content={content} />}
        {content._type === 'sectionHeading' && (
          <HeadingContent centered content={content} />
        )}
        {content._type === 'callToActionBanner' && (
          <CallToActionBanner content={content} />
        )}
        {content._type === 'imageFeatures' && (
          <ImageFeatures content={content} />
        )}
        {content._type === 'imageGallery' && <ImageGallery content={content} />}

        {content._type === 'eventListing' && <EventListing content={content} />}

        {content._type === 'tiledLinks' && <TiledLinks content={content} />}
        {content._type === 'campaigns' && <CampaignCallout content={content} />}
        {content._type === 'directorsAffiliations' && (
          <Board content={content} />
        )}
        {content._type === 'session' && <Sessions content={content} />}
      </Section>
    </React.Fragment>
  ));

export default Content;

export const Section = styled.section`
  ${({ noMargin }) => noMargin && 'margin-bottom: 0;'}
`;
