import React, { useState } from 'react';
import axios from 'axios';
import { Input, Submit, Textarea, Fieldset } from '../../styles/modules/Inputs';
import HeadingContent from '../modules/HeadingContent';
import Form from '../../styles/modules/Form';
import { SMessage, SContactForm } from './ContactForm';

const Message = ({ children }) => <SMessage>{children}</SMessage>;
const postUrl = 'https://mailer.spdrop.com/default/index.php';

const BookSession = ({ heading, copy }) => {
  const [serial, setSerial] = useState(null);
  const [honeypot, setHoneypot] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const collectData = (id) => {
    const form = global.document.getElementById(id);
    const inputs = form.querySelectorAll('input');
    const textareas = form.querySelectorAll('textarea');

    let serialString = '';

    inputs.forEach((input) => {
      serialString += `${input.name}=${input.value}&`;
    });

    textareas.forEach((input, index) => {
      serialString += `${input.name}=${input.value}${
        textareas.length === index + 1 ? '' : '&'
      }`;
    });
    setSerial(serialString);
  };

  const postForm = () => {
    axios
      .post(postUrl, serial)
      .then((response) => {
        console.log(response);
        setSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        setSubmitError(true);
      });
  };

  return (
    <SContactForm>
      <HeadingContent
        content={{
          heading,
          paragraph: copy,
        }}
      />
      {!submitted && !submitError && (
        <Form
          id="sessionForm"
          onSubmit={(e) => {
            e.preventDefault();
            postForm();
          }}
        >
          <Fieldset
            onChange={() => {
              collectData('sessionForm');
            }}
            disabled={honeypot}
          >
            <Input
              type="text"
              name="hpFirst"
              onChange={() => {
                setHoneypot(true);
              }}
            />
            <Input
              style={{ display: 'none' }}
              type="text"
              name="subject"
              value="Book a Session"
            />
            <div className="inner">
              <label htmlFor="senderName">
                <Input
                  required
                  type="text"
                  placeholder="Name"
                  id="senderName"
                  name="senderName"
                />
              </label>
              <label htmlFor="emailAddress">
                <Input
                  required
                  type="email"
                  placeholder="Email"
                  id="emailAddress"
                  name="emailAddress"
                />
              </label>
              <label htmlFor="locationRequest">
                <Input
                  type="text"
                  placeholder="Location Request"
                  id="locationRequest"
                  name="locationRequest"
                />
              </label>
              <label htmlFor="notes">
                <Textarea
                  required
                  placeholder="Notes"
                  id="notes"
                  name="notes"
                />
              </label>
            </div>
          </Fieldset>
          <Submit
            disabled={honeypot}
            type="submit"
            id="submit"
            name="submit"
            value="Send My Info"
          />
        </Form>
      )}
      {submitted && !submitError && (
        <Message>
          Thank you for Your Submission! We will get back to you as soon as we
          can!
        </Message>
      )}
      {!submitted && submitError && <Message>Something went wrong</Message>}
    </SContactForm>
  );
};

export default BookSession;
