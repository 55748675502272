import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Wrapper from '../../styles/utilities/Wrapper';
import Video from '../helpers/Video';
import HeadingContent, { HeadingContainer } from '../modules/HeadingContent';
import {
  colors,
  misc,
  spacing,
  breakpoints,
} from '../../styles/utilities/settings';
import { imageBG, absoluteCenter } from '../../styles/utilities/elements';
import BackgroundImage from '../helpers/BackgroundImage';
import ImageLoader, { SImageLoader } from '../helpers/ImageLoader';

const HeroBanner = ({ mainImage, content, content: { headingContent } }) => {
  const {
    sanityMenu: { menuItems },
  } = useStaticQuery(graphql`
    {
      sanityMenu(title: { eq: "Home Banner" }) {
        menuItems: _rawItems(resolveReferences: { maxDepth: 10 })
      }
    }
  `);

  return (
    <BackgroundImage mobile src={mainImage} alt={headingContent.heading}>
      <SHeroBanner logo={content.logo} fullscreen={content.fullScreen}>
        {content.video && <Video src={content.video} />}
        <Wrapper>
          {content.logo ? (
            <LogoContent>
              <div>
                <ImageLoader src={content.logo} width={329} visible />
              </div>
              <HeadingContent hero nav={menuItems} content={headingContent} />
            </LogoContent>
          ) : (
            <HeadingContent hero content={headingContent} />
          )}
        </Wrapper>
      </SHeroBanner>
    </BackgroundImage>
  );
};

export default HeroBanner;

export const LogoContent = styled.div`
  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div {
    @media screen and (min-width: ${breakpoints.ipadMid}px) {
      text-align: left;
    }

    &:nth-child(1) {
      width: 329px;

      @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
        width: 200px;
        margin: 0 auto 30px;
      }
    }
  }

  ${HeadingContainer} {
    max-width: 460px;

    @media screen and (min-width: ${breakpoints.ipadMid}px) {
      max-width: 560px;
      margin-left: 30px;
    }

    ul {
      margin: 0;
      list-style: none;

      a {
        color: ${colors.white};
        text-decoration: none;
        display: inline-block;

        border-bottom: 4px solid ${colors.gold};

        @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
          border-bottom: 3px solid ${colors.gold};
        }

        &:hover {
          color: ${colors.gold};
        }
      }
    }
  }

  ${SImageLoader} {
    animation: spin 60s linear infinite;
  }

  h1 {
    color: ${colors.white};
    display: inline;
    line-height: 1.5;
    font-size: 42px;
    padding-bottom: 15px;

    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      font-size: 35px;
      padding-bottom: 10px;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      font-size: 23px;
    }
  }
`;

export const SHeroBanner = styled.div`
  text-align: center;
  padding: 100px 0;
  color: ${colors.white};
  display: flex;
  align-items: center;
  min-height: 500px;
  max-height: 1000px;
  ${imageBG};

  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    height: calc(100vh - ${spacing.headerHeight});
  }

  h1 {
    color: ${colors.gold};
  }

  ${Wrapper} {
    position: relative;
    z-index: 1;

    font-size: 20px;
    line-height: 1.8;
  }

  .videoBg {
    position: absolute;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0 !important;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    transition-duration: ${misc.animSpeed};

    &.active {
      opacity: 1;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &:before {
    content: '';
    background-color: ${colors.black};
    opacity: 0.45;
    width: 100%;
    height: 100%;
    ${absoluteCenter};
    z-index: 1;
  }
`;
