import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import styled from 'styled-components';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, font, breakpoints } from '../../styles/utilities/settings';
import { secondaryButton, button } from '../../styles/utilities/elements';
import SvgLoader from '../helpers/SvgLoader';

const CampaignCallout = () => {
  const {
    sanityCampaignCallout: {
      title,
      description,
      currentCampaignTitle,
      currentCampaignDescription,
      currentCampaignUrl,
      donationUrl,
    },
  } = useStaticQuery(graphql`
    {
      sanityCampaignCallout {
        title
        description
        currentCampaignTitle
        currentCampaignDescription
        currentCampaignUrl
        donationUrl
      }
    }
  `);
  return (
    <SCampaignCallout>
      <Wrapper>
        <Box>
          <h3>{title}</h3>
          <p>{description}</p>
          <Link to="/contact">Apply to be a Campaign Recipient</Link>
        </Box>
        <Current>
          <h4>Current Campaign</h4>
          <h3>
            <SvgLoader name="star" />
            {currentCampaignTitle || 'Coming Soon'}
          </h3>
          {currentCampaignDescription && <p>{currentCampaignDescription}</p>}
          {currentCampaignUrl && (
            <a target="_blank" rel="noreferrer" href={currentCampaignUrl}>
              {`Learn More about ${currentCampaignTitle}`}
            </a>
          )}
          {donationUrl && (
            <a target="_blank" rel="noreferrer" href={donationUrl}>
              Donate to this Campaign
            </a>
          )}
        </Current>
      </Wrapper>
    </SCampaignCallout>
  );
};

export default CampaignCallout;

export const Box = styled.div`
  background-color: ${colors.gold};
  padding: 40px 50px 50px;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    border-bottom: 4px solid ${colors.black};
    margin: 0;
    padding-bottom: 8px;
    line-height: 1.1;
  }

  a {
    ${secondaryButton};
    display: block;
  }
`;

const Current = styled.div`
  color: ${colors.white};

  h4 {
    color: ${colors.gold};
    font-size: 30px;
    border-bottom: 4px solid ${colors.gold};
    padding-bottom: 8px;
    line-height: 1.1;
  }

  h3 {
    font-family: ${font.primary};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    svg {
      margin: 0 10px -2px 0;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 600;
    color: ${colors.gold};
    text-decoration: none;
    display: block;

    + a {
      margin-top: 20px;
      ${button};
      display: block;
    }
  }
`;

export const SCampaignCallout = styled.div`
  padding: 50px 0;
  background-color: ${colors.darkerGray};

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    padding: 100px 0;
  }

  ${Wrapper} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      justify-content: space-between;
    }
  }

  ${Box} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 50%;
    }
  }
  ${Current} {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: calc(45% - 30px);
    }
  }

  ${Current} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding-top: 50px;
    }
  }
`;
