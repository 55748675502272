import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../helpers/BackgroundImage';

import RichText from '../contentBlocks/RichText';
import PageLink from '../helpers/PageLink';
import { colors, breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';

const LargeFeature = ({
  content: { parallax, heading, copy, image, link, flipImageSide },
}) => (
  <SLargeFeature flip={flipImageSide}>
    <BackgroundImage parallaxBg={parallax} src={image}>
      <div className="inner">
        <Wrapper>
          <div className="content">
            {heading && <h3>{heading}</h3>}
            {copy && <RichText content={{ copy }} noWrapper />}
            {link && link.copy && (
              <PageLink
                style={{
                  marginTop: '20px',
                }}
                content={link}
              />
            )}
          </div>
        </Wrapper>
      </div>
    </BackgroundImage>
  </SLargeFeature>
);

export default LargeFeature;

export const SLargeFeature = styled.div`
  .inner {
    padding: 100px 15px;

    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      padding: 200px 50px;
    }
  }

  &:nth-child(1n) {
    .content {
      margin: 0 auto;

      @media screen and (min-width: ${breakpoints.ipadLand}px) {
        margin: ${({ flip }) => (flip ? '0 0 0 auto' : '0 auto 0 0')};
      }
    }
  }

  &:nth-child(2n) {
    .content {
      margin: 0 auto;

      @media screen and (min-width: ${breakpoints.ipadLand}px) {
        margin: ${({ flip }) => (flip ? '0 auto 0 0' : '0 0 0 auto')};
      }
    }
  }

  .content {
    background-color: ${colors.blue};
    padding: 30px 15px;
    max-width: ${breakpoints.pageWidth / 2}px;
    color: ${colors.white};

    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      padding: 30px;
    }
  }
`;
